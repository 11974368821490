import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { createI18n } from 'vue-i18n';
import translations from './translations.json';
import * as Sentry from '@sentry/vue';

// This is to make sure that all images are included in the build
import.meta.glob([
    '../assets/images/**',
    '../assets/logos/**',
]);

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

const i18n = createI18n({
    legacy: false,
    locale: navigator.language,
    fallbackLocale: 'en',
    messages: translations,
    missingWarn: false,
    fallbackWarn: false,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
});

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue'),
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        Sentry.init({
            app,
            enabled: !!import.meta.env.VITE_SENTRY_VUE_DSN,
            dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
            environment: import.meta.env.VITE_APP_ENV,
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
        });

        return app.use(plugin).use(i18n).use(ZiggyVue).mount(el);
    },
    progress: {
        color: '#1578be',
    },
});
